@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid-cols-\[minmax\(0px\2c _1fr\)_minmax\(0px\2c _1fr\)_50px\] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) 50px;
}

.grid-cols-\[minmax\(0px\2c _1fr\)_minmax\(0px\2c _1fr\)_minmax\(0px\2c _1fr\)_50px\] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 50px;
}

.grid-cols-\[minmax\(0px\2c _1fr\)_minmax\(0px\2c _1fr\)_minmax\(0px\2c _1fr\)_minmax\(0px\2c _1fr\)_minmax\(0px\2c _1fr\)_50px\] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 50px;
}

.grid-cols-\[minmax\(0px\2c _1fr\)_minmax\(0px\2c _1fr\)_minmax\(0px\2c _1fr\)_minmax\(0px\2c _1fr\)_minmax\(0px\2c _1fr\)_minmax\(0px\2c _1fr\)_minmax\(0px\2c _1fr\)_50px\] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 50px;
}

.spinner {
    display: block;
    box-sizing: border-box;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, .2);
    border-top-color: transparent;
    animation: spinner2 800ms linear infinite;
}

.spinner-parent-overlap {
    overflow: hidden;
    max-width: 100vw;
    max-height: 100vh;
}

.spinner--inlined {
    display: inline-block;
}

.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    box-sizing: border-box;
}

.spinner-wrapper--default {
    margin: 8px;
}

.spinner-wrapper--default .spinner {
    width: 24px;
    height: 24px;
}

.spinner-wrapper--full-height {
    background-color: #0caad0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    z-index: 49;
}

.spinner-wrapper--full-height .spinner {
    border: 2px solid rgba(255, 255, 255, .9);
    border-top-color: transparent;
}

.spinner-wrapper--button {
    margin: 0 0 0 8px;
    padding: 0;
}

.spinner-wrapper--button .spinner {
    width: 16px;
    height: 16px;
    border: 2px solid currentColor;
    border-top-color: transparent;
}

.spinner-wrapper--tall {
    padding-bottom: 16px;
}

@keyframes spinner2 {
    to {
        transform: rotate(360deg);
    }
}

.h-fit {
    height: fit-content;
}
